import React, {useState} from "react";

import {
	Col,
	Collapse,
	Container,
	Nav,
	NavItem,
	NavLink,
	Row
} from "reactstrap";

import {NavButton, ToggleButton} from "./Buttons";
import {InfoModalButton} from "./Buttons";
import {
	AppVersion,
	LocationWarning,
} from "./GeneralPageElements";
import {REPORT} from "../static_props/MyTree";
import {MYTREE} from "../../utilities/resources";

import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import {
	truncNum,
	formatCurrency,
} from "../../utilities/utilities";

import itree from "../../img/iTree_primary_logo_restricted_SMALL.png";


export const Annual = (
	{
		location,
		tab,
		value,
		currency,
	}
) => {
	let unitedStates = ["us", "usa", "united states"]
	let locatedInTheUS = unitedStates.includes(location.nation.toLowerCase());
	let href = `https://ourtrees.itreetools.org/#/report?longitude=${location.longitude}&latitude=${location.latitude}`;

	return (
		<React.Fragment>
			<Row>
				<Col>
					<strong>{REPORT.total[tab]}</strong>
					<span className={"float-end"}>
						{formatCurrency(value, currency)}
					</span>
				</Col>
			</Row>
			{locatedInTheUS ?
				<Row>
					<Col>
						<p className={"text-center mb-n2"}>
							Discover benefits of all
							your <a target={"_blank"} href={href}>community
							trees</a>!
						</p>
					</Col>
				</Row>
				:
				null
			}
		</React.Fragment>
	)
};


export const BenefitsTable = (
	{
		tab,
		unitType,
		benefits,
		currency,
		proximity,
	}
) => {
	return (
		<React.Fragment>
			<Bar
				text={tab === 0 ? "Annual values:" : null}
			/>
			<AnnualCarbon
				tab={tab}
				type={unitType}
				co2={benefits.co2}
				carbon={benefits.carbon}
				currency={currency}
			/>
			<HydroBenefit
				type={unitType}
				benefits={benefits.hydro}
				currency={currency}
			/>
			<PollutionRemoved
				tab={tab}
				type={unitType}
				benefits={benefits.air}
				currency={currency}
			/>
			{proximity === 0 ?
				<React.Fragment>
					<EnergyBenefit
						tab={tab}
						type={unitType}
						benefits={benefits.energy}
						currency={currency}
					/>
					<PollutionAvoided
						type={unitType}
						benefits={benefits.air}
						co2={benefits.co2}
						currency={currency}
					/>
				</React.Fragment>
				:
				null
			}
			{tab === 0 ?
				<React.Fragment>
					<Bar
						text={tab === 0 ? "Values are totals to date:" : null}
					/>
					<TotalCarbon
						type={unitType}
						co2={benefits.co2}
						carbon={benefits.carbon}
						currency={currency}
					/>
				</React.Fragment>
				:
				null
			}

			<HeavyLine/>
		</React.Fragment>
	)
};


export const Condition = (
	{
		condition,
	}
) => {
	return (
		<Row>
			<Col className={"text-capitalize"}>
				<strong>Condition:</strong> {condition}
			</Col>
		</Row>
	)
};


export const Location = (
	{
		location,
	}
) => {
	return (
		<Row>
			<Col className={"text-capitalize"}>
				<strong>Location:</strong> {location}
			</Col>
		</Row>
	)
};


export const Header = (
	{
		activeTab,
	}
) => {
	return (
		<React.Fragment>
			<Row className={"pt-3"}>
				<Col>
					<p className={"h3 fw-bold float-start"}>
						{REPORT.headline}
					</p>
					<img
						className={"float-end d-none d-sm-block small-image"}
						src={itree}
						alt="i-Tree Logo"
					/>
				</Col>
			</Row>
			<Row className={"mt-n2"}>
				<Col>
					<div className={"d-flex justify-content-between"}>
						<p className={"h5 fw-bold"}>
							{REPORT.subheading[activeTab]}
						</p>
						<img
							className={"d-inline d-sm-none small-image"}
							src={itree}
							alt="i-Tree Logo"
						/>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	)
};


export const TabToggle = (props) => {
	return (
		<Row>
			<Col
				xl={{size: 4, offset: 4}}
			>
				<Nav
					className={"d-flex justify-content-center mt-3"}
					tabs
				>
					{props.tabs.map((tab, index) => {
						return (
							<NavItem key={tab}>
								<NavLink
									className={
										props.selected === index ? props.activeTabClasses : ""
									}
									role={"button"}
									onClick={
										() => props.function(
											index
										)
									}
								>
									{tab}
								</NavLink>
							</NavItem>
						)
					})}
				</Nav>
			</Col>
		</Row>
	)
}


export const Controls = (
	{
		className,
		toggleFunction,
		id,
		selected,
		settings,
	}
) => {
	return (
		<Container className={"mt-3"}>
			<Row>
				<Col
					xs={{size: 2}}
					sm={{size: 3}}
					lg={{size: 4}}
				>
					<NavButton
						block={false}
						className={"round float-end"}
						color={"success"}
						leftIcon={"arrow-left"}
						path={"/dashboard"}
					/>
				</Col>
				<Col
					xs={{size: 8}}
					sm={{size: 6}}
					lg={{size: 4}}
				>
					<ToggleButton
						className={className || "w-100"}
						id={id}
						selected={selected}
						settings={settings}
						toggleFunction={toggleFunction}
					/>
				</Col>
				<Col
					xs={{size: 2}}
					sm={{size: 3}}
					lg={{size: 4}}
				>
					<InfoModalButton
						subject={"factsheetMeaning"}
					/>
				</Col>
			</Row>
		</Container>
	)
};


export const ServingSize = (
	{
		diameter,
		unitType,
		total,
		count,
	}
) => {
	let [d, u] = [parseFloat(diameter), "in"];

	if (unitType === false) {
		d = d * 2.54;
		u = "cm";
	}

	if (total) {
		return (
			<Row>
				<Col>
					<strong>Serving Size: </strong>
					{count} {count >= 2 ? "trees" : "tree"}
				</Col>
			</Row>
		)
	} else {
		return (
			<Row>
				<Col>
					<strong>Serving Size: </strong>
					{d.toFixed(2)} {u}. diameter
				</Col>
			</Row>
		)
	}
};


export const HeavyLine = () => {
	return (
		<Row>
			<Col>
				<div className={"hvy"}/>
			</Col>
		</Row>
	)
};


export const Bar = (
	{
		text,
	}
) => {
	return (
		<Row>
			<Col>
				<div className={"bar"}>
					<small className={"fw-bold text-light float-end me-2"}>
						{text}
					</small>
				</div>
			</Col>
		</Row>
	)
};


export const TreeName = (
	{
		name = null,
		common = null,
		scientific = null,
	}
) => {
	return (
		<Row>
			<Col>
				{name ? <strong>{name}</strong> : null}
				<p>
					{common}, <em>({scientific})</em>
				</p>
			</Col>
		</Row>
	)
};


export const Bottom = (
	{
		meta = null,
		warning = null,
		bottom,
		tab,
		finePrintIsOpen,
		setFinePrintIsOpen,
	}
) => {
	let toggleCollapse = () => {
		let element = document.getElementById("fine-print-collapse");
		setFinePrintIsOpen(
			!element.classList.contains("show")
		);
	};

	let icon = <FAI icon={["fas", finePrintIsOpen ? "minus" : "plus"]}/>;

	return (
		<footer id={"footer"}>
			<Row>
				<Col>
					{bottom.blurb}
				</Col>
			</Row>
			<Row>
				<Col>
					{bottom.valueDeclaration}
				</Col>
			</Row>
			<Row
				className={"mt-3 text-center"}
				data-html2canvas-ignore={"true"}
			>
				<Col>
					<div
						className={"text-underline cursor pointer"}
						onClick={
							() => {
								toggleCollapse()
							}
						}
					>
						<p>
							{icon}
							{" "} Read the fine print.
						</p>
					</div>
				</Col>
			</Row>
			<Collapse
				id={"fine-print-collapse"}
	            isOpen={finePrintIsOpen}
			>
				{bottom.footnotes.map((note, index) => {
					return (
						<Row key={index} className={"mt-2"}>
							<Col>
								<sup>{index + 1}</sup> {note}
							</Col>
						</Row>
					)
				})}
				{MYTREE ?
					<Row className={"mt-2"}>
						<Col>
							<sup>5</sup>{" "}
							{warning ?
								<span>
									This location is not yet supported
									by i-Tree. Until funded, US national
									averages have been used to estimate
									its tree benefits.
								</span>
								:
								<span>
									This location is supported by i-Tree.
									Localized data have been used to
									estimate its tree benefits.
								</span>
							}
						</Col>
					</Row>
					:
					null
				}
				<Row className={"mt-2"}>
					<Col>
						{bottom.abbreviations}
					</Col>
				</Row>
				{warning ?
					<Row className={"mt-2 text-center"}>
						<Col>
							<LocationWarning/>
						</Col>
					</Row>
					:
					null
				}
				<Row className={"my-2"}>
					<Col className={"text-center"}>
						<div>
							{bottom.version ||
								<span>MyTree<AppVersion/>{tab === 1 ?
									<sub>-beta</sub> : null}</span>}
						</div>
						<div>
							{meta ?
								<React.Fragment>
									<div>
										<em>
											Powered by the i-Tree
											Engine{" "}
											<sub>{meta.engineVersion}</sub>
										</em>
									</div>
									<div>
										<em>
											Location Species Database{" "}
											<sub>{meta.dbVersion}</sub>
										</em>
									</div>
								</React.Fragment>
								:
								<em>
									Powered by
									i-Tree {bottom.poweredBy}
								</em>
							}
						</div>
					</Col>
				</Row>
			</Collapse>
		</footer>
	);
}

export const PollutionAvoided = (
	{
		type,
		benefits,
		co2,
		currency,
	}
) => {
	let unit = type ? ["oz", 35.274] : ["g", 1000];
	let co2_factor = type ? ["lbs", 2.2046] : ["kg", 1];
	let value = benefits.totalAvoidedDollarValue() + parseFloat(co2.CO2AvoidedValue);
	let co2Avoided = co2.CO2Avoided * co2_factor[1];
	let co = benefits.COAvoided * unit[1];
	let no2 = benefits.NO2Avoided * unit[1];
	let so2 = benefits.SO2Avoided * unit[1];
	let pm25 = benefits.PM25Avoided * unit[1];

	value = formatCurrency(value, currency);
	co2Avoided = co2Avoided > 0 && co2Avoided < 0.1 ? "< 0.1" : truncNum(co2Avoided);
	co = co > 0 && co < 0.1 ? "< 0.1" : truncNum(co);
	no2 = no2 > 0 && no2 < 0.1 ? "< 0.1" : truncNum(no2);
	so2 = so2 >= 0 && so2 < 0.1 ? "< 0.1" : truncNum(so2);
	pm25 = pm25 >= 0 && pm25 < 0.1 ? "< 0.1" : truncNum(pm25);

	let lines = [
		{value: value, unit: null, title: "Avoided Energy Emissions",},
		{value: co2Avoided, unit: co2_factor[0], title: "Carbon Dioxide",},
		{value: co, unit: unit[0], title: "Carbon Monoxide",},
		{value: no2, unit: unit[0], title: "Nitrogen Dioxide",},
		{value: so2, unit: unit[0], title: "Sulfur Dioxide",},
		{
			value: pm25,
			unit: unit[0],
			title: <span>PM<sub>2.5</sub></span>,
			horizontalRule: false
		},
	];

	return (
		<ReportLines lines={lines}/>
	);
}

export const PollutionRemoved = (
	{
		type,
		benefits,
		currency,
	}
) => {
	let unit = type ? ["oz", 35.274] : ["g", 1000];
	let value = benefits.totalRemovedDollarValue();
	let co = parseFloat(benefits.CORemoved) * unit[1];
	let o3 = parseFloat(benefits.O3Removed) * unit[1];
	let no2 = parseFloat(benefits.NO2Removed) * unit[1];
	let so2 = parseFloat(benefits.SO2Removed) * unit[1];
	let pm25 = parseFloat(benefits.PM25Removed) * unit[1];

	value = formatCurrency(value, currency);
	co = co > 0 && co < 0.1 ? "< 0.1" : truncNum(co);
	o3 = o3 > 0 && o3 < 0.1 ? "< 0.1" : truncNum(o3);
	no2 = no2 > 0 && no2 < 0.1 ? "< 0.1" : truncNum(no2);
	so2 = so2 > 0 && so2 < 0.1 ? "< 0.1" : truncNum(so2);
	pm25 = pm25 > 0 && pm25 < 0.1 ? "< 0.1" : truncNum(pm25);

	let lines = [
		{value: value, unit: null, title: "Air Pollution Removal",},
		{value: co, unit: unit[0], title: "Carbon Monoxide",},
		{value: o3, unit: unit[0], title: "Ozone",},
		{value: no2, unit: unit[0], title: "Nitrogen Dioxide",},
		{value: so2, unit: unit[0], title: "Sulfur Dioxide",},
		{value: pm25, unit: unit[0], title: <span>PM<sub>2.5</sub></span>,},
	];

	return (
		<ReportLines lines={lines}/>
	);
}

export const AnnualCarbon = (
	{
		type,
		co2,
		carbon,
		currency,
	}
) => {
	let unit = type ? ["lbs", 2.2046] : ["kg", 1];
	let co2SequesteredValue = formatCurrency(co2.CO2SequesteredValue, currency);
	let carbonSequestered = carbon.CarbonSequestration * unit[1];
	carbonSequestered = carbonSequestered >= 0.1 ? truncNum(carbonSequestered) : "< 0.10";
	let co2Sequestered = co2.CO2Sequestered * unit[1];
	co2Sequestered = co2Sequestered >= 0.1 ? truncNum(co2Sequestered) : "< 0.10";

	let lines = [
		{
			value: co2SequesteredValue,
			unit: null,
			title: "Carbon Dioxide Uptake",
		},
		{
			value: carbonSequestered,
			unit: unit[0],
			title: <span>Carbon Sequestered<sup>1</sup></span>,
		},
		{
			value: co2Sequestered,
			unit: unit[0],
			title: <span>CO<sub>2</sub> Equivalent<sup>2</sup></span>,
		},
	];

	return (
		<ReportLines lines={lines}/>
	);
}

export const TotalCarbon = (
	{
		type,
		carbon,
		currency,
	}
) => {
	let unit = type ? ["lbs", 2.2046] : ["kg", 1];
	let co2Value = formatCurrency(carbon.CarbonDioxideStorageValue, currency);

	let carbonStorage = carbon.CarbonStorage * unit[1];
	carbonStorage = carbonStorage >= 0.1 ? truncNum(carbonStorage) : "< 0.10";

	let co2Storage = carbon.CarbonDioxideStorage * unit[1];
	co2Storage = co2Storage >= 0.1 ? truncNum(co2Storage) : "< 0.10";

	let lines = [
		{
			value: co2Value,
			unit: null,
			title: <span>Carbon Dioxide Uptake<sup>4</sup></span>
		},
		{
			value: carbonStorage,
			unit: unit[0],
			title: <span>Carbon Storage<sup>4</sup></span>,
		},
		{
			value: co2Storage,
			unit: unit[0],
			title: <span>CO<sub>2</sub> Equivalent<sup>2, 4</sup></span>,
			horizontalRule: false
		},
	];

	return (
		<ReportLines lines={lines}/>
	);
}

export const EnergyBenefit = (
	{
		benefits,
		currency,
		tab,
	}
) => {
	let transformed = benefits.transformEnergyBenefits();
	let value = formatCurrency((transformed.ElectricityDollarValue.value + transformed.NaturalGasDollarValue.value), currency);
	let electricity = truncNum(transformed.ElectricitykWhSaved.value);
	let fuel = truncNum(transformed.NaturalGasThermsSaved.value);

	let lines = [
		{
			value: value,
			unit: null,
			title: <span>Energy Usage{tab === 0 ? " Per Year" : ""}<sup>3</sup></span>
		},
		{value: electricity, unit: "kWh", title: "Electricity Savings",},
		{value: fuel, unit: "MMBtu", title: "Heating Fuel Savings",},
	]

	return (
		<ReportLines lines={lines}/>
	);
}

export const HydroBenefit = (
	{
		type,
		benefits,
		currency,
	}
) => {
	let unit = type ? ["gal", 1] : ["L", 3.78541];
	let value = benefits.RunoffAvoidedValue;
	// RunoffAvoided and Interceptions are cubic meters,
	// multiplied by 264.172 to convert to gallons.
	let avoided = benefits.RunoffAvoided * 264.172 * unit[1];
	let intercepted = benefits.Interception * 264.172 * unit[1];

	value = formatCurrency(value, currency);
	avoided = avoided >= 0.1 ? truncNum(avoided) : "< 0.10";
	intercepted = intercepted >= 0.1 ? truncNum(intercepted) : "< 0.10";

	let lines = [
		{value: value, unit: null, title: "Storm Water Mitigation",},
		{value: avoided, unit: unit[0], title: "Runoff Avoided",},
		{value: intercepted, unit: unit[0], title: "Rainfall Intercepted",},
	];

	return (
		<ReportLines lines={lines}/>
	);
}

export const ReportLines = (
	{
		lines
	}
) => {
	return (
		<Row>
			<Col>
				{lines.map((line, index) => {
					return (
						<React.Fragment key={`${line.title}-${line.value}`}>
							<span className={"d-flex justify-content-between"}>
								<p className={`mb-0 ${index === 0 ? "fw-bold" : "indent"}`}>
									{line.title}
								</p>
								<p className={`mb-0 ${index === 0 ? "fw-bold" : ""}`}>
									{line.value} {line.unit ? line.unit : null}
								</p>
							</span>
							{line.horizontalRule === false ? null : <hr/>}
						</React.Fragment>
					)
				})}
			</Col>
		</Row>
	);
};
