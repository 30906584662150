import React, {Component} from 'react';

import {
	Container,
	Alert,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Row,
	Col,
} from "reactstrap";

import '../../stylesheets/nutrition.scss';

import * as Parts from "../parts/NutritionLabelParts";
import {Equivalents} from "../parts/Equivalents";
import {SocialSharing} from "../SocialSharing/SocialSharing";

import {Project} from "../../classes/project";
import {Benefits} from "../../classes/benefits";

import {DEVMODE, TABS} from "../../utilities/resources";
import {REPORT} from "../static_props/MyTree";


export class IndividualBenefits extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ready: false,
			message: "Loading tree benefits...",
			project: new Project(),
			activeTab: 1,
			activeSubTab: 0,
			finePrintIsOpen: false,
		}
		this.url = window.location.toString();
	};

	setFinePrintIsOpen = (value) => {
		this.setState({
			finePrintIsOpen: value,
		})
	};

	async componentDidMount() {
		if (this.props.location.pathname.includes("benefits/individual")) {
			if (this.props.project.error) {
				this.setState({
					message: this.props.project.error,
				});
			} else {
				let {project} = {...this.props};
				if (project.benefits.calculated) {
					this.setState({
						ready: true,
						project: project,
					});
				}
			}
		} else {
			let project = new Project();
			project.readURL(this.props.location.pathname);
			let timeline = "forwards";
			let years = 20;
			let report = "full";
			let benefitType = "cumulative";
			let options = {timeline, years, report, benefitType};
			let currentResponse = await Benefits.getBenefits(project);
			let futureResponse = await Benefits.getBenefits(project, options);

			// FIXME: This is redundant with MyTree.js getBenefits()
			project.benefits.assignBenefits(currentResponse.benefits[0]);
			project.futureBenefits.assignBenefits(futureResponse.benefits[19]);
			project.benefits.meta = currentResponse.meta;
			project.futureBenefits.meta = futureResponse.meta;

			this.setState({
				ready: true,
				project: project,
			});
		}
	};

	toggle = (tab) => {
		this.setState({
			activeTab: tab,
		});
	};

	toggleSubTab = (tab) => {
		this.setState({
			activeSubTab: tab,
		});
	};

	render() {
		let benefitsToDisplay;
		let {
			benefits,
			futureBenefits,
			tree,
			building,
			location,
		} = {...this.state.project};
		let body = `This is a MyTree project for a ${tree.common} tree.`;
		let emailURL = `${window.location.origin}#/benefits/${this.state.project.__url()}&emailed=true`;

		let locationForDisplay;
		if (typeof location.getAbbreviatedLocation !== "undefined") {
			locationForDisplay = location.getAbbreviatedLocation();
		}

		if (this.state.activeTab === 0) {
			benefitsToDisplay = {...benefits};
		} else {
			benefitsToDisplay = {...futureBenefits};
		}

		let activeTabClasses = "active border border-primary";
		let subTabs = [
			"Benefits",
			"Equivalents",
		];

		if (this.state.ready) {
			return (
				<React.Fragment>
					{this.state.project.error ?
						<Alert color={"danger"}>
							{this.state.project.error}
						</Alert>
						:
						null
					}
					{/*{this.state.project.warning ?*/}
					{/*	<Alert color={"warning"}>*/}
					{/*		{this.state.project.warning}*/}
					{/*	</Alert>*/}
					{/*	:*/}
					{/*	null*/}
					{/*}*/}
					<Parts.Controls
						toggleFunction={this.toggle}
						id={"benefitsYearToggle"}
						selected={this.state.activeTab}
						settings={TABS}
					/>
					<Parts.TabToggle
						function={this.toggleSubTab}
						selected={this.state.activeSubTab}
						tabs={subTabs}
						activeTabClasses={activeTabClasses}
					/>
					<TabContent activeTab={this.state.activeSubTab}>
						<TabPane tabId={0}>
							<Container
								className={"nutrition"}
								id={`${this.state.activeTab === 0 ? "now" : "future"}-benefits`}
							>
								<Parts.Header
									activeTab={this.state.activeTab}
								/>
								<Parts.TreeName
									name={this.state.project.note}
									common={tree.common}
									scientific={tree.scientific}
								/>
								<Parts.ServingSize
									diameter={tree.diameter}
									unitType={this.props.unitType}
								/>
								<Parts.Condition
									condition={tree.getVerboseCondition()}
								/>
								{locationForDisplay.length ?
									<Parts.Location
										location={locationForDisplay}
									/>
									:
									null
								}
								<Parts.Annual
									tab={this.state.activeTab}
									location={this.state.project.location}
									currency={this.props.currency}
									value={
										benefitsToDisplay.totalDollarValue(
											building.proximity === 0
										)
									}
								/>
								<Parts.BenefitsTable
									tab={this.state.activeTab}
									unitType={this.props.unitType}
									currency={this.props.currency}
									benefits={benefitsToDisplay}
									proximity={building.proximity}
								/>
								<Parts.Bottom
									{...REPORT}
									tab={this.state.activeTab}
									warning={this.state.project.warning}
									meta={benefitsToDisplay.meta}
									finePrintIsOpen={this.state.finePrintIsOpen}
									setFinePrintIsOpen={this.setFinePrintIsOpen}
								/>
								<SocialSharing
									body={body}
									canvasTarget={`${this.state.activeTab === 0 ? "now" : "future"}-benefits`}
									fileName={"MyTree_Benefits_Report.png"}
									url={emailURL}
									finePrintIsOpen={this.state.finePrintIsOpen}
									setFinePrintIsOpen={this.setFinePrintIsOpen}
								/>
							</Container>
						</TabPane>
						<TabPane tabId={1}>
							<Equivalents
								id={`${this.state.activeTab === 0 ? "now" : "future"}-equivalents`}
								scientific={tree.scientific}
								activeTab={this.state.activeTab}
								benefits={benefitsToDisplay}
								common={tree.common}
								locationForDisplay={locationForDisplay.length ? locationForDisplay : null}
								name={this.state.project.note}
								unitType={this.props.unitType}
							>
								{{
									intro:
										<p className={"h5 fw-bold text-center mt-3"}>
											With care and maintenance,<br/>
											after {this.state.activeTab === 0 ? "1 year" : "20 years"}{" "}
											this tree<br/>could potentially:</p>,
									label: <div>
										<p className={"h3 fw-bold"}>
											MyTree
										</p>
										<p className={"mt-n2 h5 fw-bold"}>Works hard for me!</p>
									</div>,
									sharing: <SocialSharing
										body={body}
										canvasTarget={`${this.state.activeTab === 0 ? "now" : "future"}-equivalents`}
										fileName={"MyTree_Equivalents_Report.png"}
										url={emailURL}
										finePrintIsOpen={this.state.finePrintIsOpen}
										setFinePrintIsOpen={this.setFinePrintIsOpen}
									/>,
								}}
							</Equivalents>
						</TabPane>
					</TabContent>
				</React.Fragment>
			)
		} else {
			return (
				<div className={"mt-5 px-5 d-flex justify-content-center align-items-center"}>
					<h3>{this.state.message}</h3>
				</div>
			)
		}
	}
}
